import { Modal } from '@care/react-component-lib';
import { CardMedia } from '@mui/material';
import { FC } from 'react';
import { theme } from '@care/material-ui-theme';

interface IModalVideo {
  dataTestId: string;
  height?: string;
  isVisible: boolean;
  onClose: () => void;
  title: string;
  videoUrl: string;
}

const ModalVideo: FC<IModalVideo> = ({
  isVisible,
  title,
  videoUrl,
  height,
  onClose,
  dataTestId,
}) => {
  return (
    <Modal
      variant="dynamic"
      onClose={onClose}
      open={isVisible}
      title={title}
      sx={{ '.MuiPaper-root': { width: '600px' } }}>
      <CardMedia
        allow="autoplay; fullscreen"
        sx={{
          borderRadius: 0,
          [theme.breakpoints.down('md')]: {
            minWidth: 0,
          },
        }}
        component="iframe"
        data-testid={dataTestId}
        height={height ?? 360}
        image={videoUrl}
        title={title}
      />
    </Modal>
  );
};

export default ModalVideo;
