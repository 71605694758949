import { FC } from 'react';
import { Box } from '@mui/material';
import { Loader } from '@care/react-component-lib';

interface LoadingSpinnerProps {
  inline?: boolean;
  testId?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { inline, testId } = props;

  if (inline) {
    return (
      <Box data-testid={testId ?? 'loading-spinner'} my="20px" textAlign="center">
        <Loader sx={{ display: 'inline-block' }} />
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      data-testid={testId ?? 'loading-spinner'}
      display="flex"
      height="100vh"
      justifyContent="center"
      {...props}>
      <Loader />
    </Box>
  );
};

export default LoadingSpinner;
